// import { fetch, addTask } from 'domain-task';
import { getConfig } from '../config';

var initialState = {
    isLoading: false,
    recentProducts: [],
    allergener: []
};

const apiUrl = getConfig().API.WebApiBaseUrl;

export function fetchAllergener() {
    return (dispatch, getStore) => {
        const url = apiUrl + "api/products/allergens";
        let task = fetch(url)
            .then(data => data.json())
            .then(data => dispatch(allergenerFetchedAction(data)))
            .catch(error => {
                console.log(JSON.stringify(error));
                dispatch({
                    type: "FETCH_ALLERGENER_FAILED"
                });
            });
        // addTask(task);
        dispatch({ type: "FETCHING_ALLERGENER" });        
        console.log("CONFIG ER : ", getConfig().API.WebApiBaseUrl);
    };
}

export function fetchProduct(id) {
    return (dispatch, getStore) => {
        var searchId = getStore().SearchReducer.searchId;
        var productRank = getStore().ProductReducer.listNumber;

        var url = apiUrl + "api/products/" + id;

        if (searchId !== undefined && productRank !== undefined)
        {
            url += "?searchId=" + searchId + "&productRank=" + productRank; 
        }

        let task = fetch(url)
            .then(data => data.json().then(data => dispatch(getProductAction(data))))
            .catch(error => { console.log(JSON.stringify(error)); dispatch({ type: "REQUEST_PRODUCT_FAILED" });});
            
        // addTask(task);
        dispatch({ type: "REQUESTING_PRODUCT" });
    }
}

export function allergenerFetchedAction (allergener) {
    return {
        type: "ALLERGENER_FETCHED",
        allergener
    };
}

export function getProductAction(product) {
    return {
        type: "GET_PRODUCT",
        product: product
    }
}

export function getRecentProducts(top) {
    return (dispatch, getStore) => {

        let task = fetch(apiUrl + "api/products/latest/" + top)
            .then(data => data.json().then(data => dispatch({type: "GOT_RECENT_PRODUCTS", products: data})))
            .catch(error => { console.log(JSON.stringify(error)); dispatch({ type: "GET_RECENT_PRODUCTS_FAILED" }); });


        // addTask(task);
        dispatch({ type: "REQUESTING_RECENT_PRODUCTS" });
    }
}

export const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUESTING_PRODUCT":
            return Object.assign({}, state, {
                isLoading: true,
                notFound: false
            });
        case "GET_PRODUCT":
            return Object.assign({}, state, {
                product: action.product,
                isLoading: false,
                notFound: false
            });
        case "SET_LIST_NUMBER":
            return Object.assign({}, state, {
                listNumber: action.number
            });
        case "REQUEST_PRODUCT_FAILED":
            return Object.assign({}, state, {
                product: null,
                isLoading: false,
                notFound: true
            });
        case "GOT_RECENT_PRODUCTS":
            return Object.assign({}, state, {
                recentProducts: action.products
            });
        case "FETCHING_ALLERGENER":
            return Object.assign({}, state, {
                isLoading: true,
                notFound: false
            });
        case "ALLERGENER_FETCHED":
            return Object.assign({}, state, {
                allergener: action.allergener,
                isLoading: false,
                notFound: false
            });
        case "FETCH_ALLERGENER_FAILED":
            return Object.assign({}, state, {
                isLoading: false,
                notFound: false
            });
        default:
            return state;
    }
}