import React from 'react';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { getCompanyInfo, companyInfinityScroll } from '../../redux/company';
import { filterLeverandor } from '../../redux/searchfilter';
import ProductList from '../ProductSearch/ProductList';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';

let infinityScrollTimeout = true;
class CompanyOverview extends React.Component {
	constructor(props) {
		super(props);

		this.infinityscrollLoad = this.infinityscrollLoad.bind(this);
		this.renderCrawlerLink = this.renderCrawlerLink.bind(this);
        this.requestTick = this.requestTick.bind(this);
        this.update = this.update.bind(this);
        this.getBackgroundImageSize = this.getBackgroundImageSize.bind(this);

		this.state = {
			scroll: 50
		}
	}

	componentWillMount() {
		if (this.props.match.params && this.props.match.params.companyID) {
			this.props.getCompany(this.props.match.params.companyID);
		}
		setTimeout(() => {
			infinityScrollTimeout = false;
		}, 3000);
	}

	componentDidMount() {
		document.title = 'Leverandør - VetDuAt';
        this.lastKnownScroll = 0;
        this.ticking = false;
        //window.addEventListener('scroll', this.requestTick);
        this.heroHeight = this.getBackgroundImageSize(document.getElementsByClassName('Product__producer-hero')[0]);

		window.scrollTo(0,0);
	}

    componentWillUnmount() {
        this.lastKnownScroll = 0;
        this.ticking = false;
        //window.removeEventListener('scroll', this.requestTick);
    }

	componentWillReceiveProps(nextProps) {
		if (nextProps.companyinfo.navn) document.title = nextProps.companyinfo.navn + ' - Leverandør - VetDuAt';
	}

	//Infinityscroll
	infinityscrollLoad() {
		if (this.props.list.length < this.props.resultcount && !infinityScrollTimeout) {
			let skip = this.props.match.params.skip;
			if (skip === undefined) {
				skip = 0;
			}
			this.props.loadMoreResults('', this.props.list.length + parseInt(skip));
		}
	}

    requestTick() {
        if (!this.ticking) {
            this.ticking = true;
            this.lastKnownScroll = window.pageYOffset;
            window.requestAnimationFrame(this.update);
        }
    }

    update() {
        let scroll = this.lastKnownScroll;
        if(this.heroHeight > 0 && !(window.pageYOffset >= 800)){
            this.setState({
                scroll: 50 + (-((scroll * 100) / this.heroHeight))
            })
        }
        this.ticking = false;
	}

	renderCrawlerLink() {
		let skip = this.props.match.params.skip;
		if (skip === undefined) {
			skip = 0;
		}
		if (this.props.resultcount === undefined || parseInt(skip) + 20 >= parseInt(this.props.resultcount)) {
			return '';
		}

		return (
			<a className="crawlerskiplink" href={'/firma/' + this.props.match.params.name + '/' + (parseInt(skip) + 20)}>
				Flere produkter fra {this.props.match.params.name}
			</a>
		);
    }

    getBackgroundImageSize(e) {
        var img = document.createElement('img');
        img.src = this.props.companyinfo.miljobildeUrl;
		var that = this;
        img.onload = function() {
            that.heroHeight = this.height;
        }
	}

    ensureHttpPrefix(url) {
        if (url.indexOf('http') == -1) {
            return 'http://' + url;
        }
        return url;
    }

    lineshiftToBreaks(text) {
        if (text) {
            return text.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
        }
    }

    render() {
        return !this.props.searchDone || this.props.company_isLoading ? (
            <PageLoadSpinner />
		) : (
			<div>
				<div className="Product__producer row">
					<div className="container-fluid">
						<div className="row">
							<div
								className="col Product__producer-hero"
								style={{ backgroundImage: 'url(' + this.props.companyinfo.miljobildeUrl + ')', backgroundPositionY: this.state.scroll + '%' }}
							>
								<div className="container">
									{!this.props.companyinfo.miljobildeUrl && (
										<h1 className="Product__producer-name">{this.props.companyinfo.navn}</h1>
									)}
									{this.props.companyinfo.logoUrl && (
										<div className="Product__producer-logo-container">
											<img
												className="Product__producer-logo"
												src={this.props.companyinfo.logoUrl}
												alt={`${this.props.companyinfo.navn}'s logo`}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					{(this.props.companyinfo.beskrivelse || this.props.companyinfo.hjemmesideUrl) && (
						<div className="container">
							<div className="row">
								<div className="Product__producer-description-container col">
									{this.props.companyinfo.navn && (
										<h1 className="Product__producer-title">Om {this.props.companyinfo.navn}</h1>
									)}
									{this.props.companyinfo.beskrivelse && (
										<div dangerouslySetInnerHTML={{__html : this.props.companyinfo.beskrivelse}} /> 
                                        // <p className="Product__producer-description">
                                        //     {renderHTML(this.lineshiftToBreaks(Linkify(this.props.companyinfo.beskrivelse)))}
                                        // </p>
									)}
									{this.props.companyinfo.hjemmesideUrl && (
										<p className="Product__producer-web">
											Besøk oss på{' '}
                                            <a href={this.ensureHttpPrefix(this.props.companyinfo.hjemmesideUrl)} target="_blank">
												{this.props.companyinfo.hjemmesideUrl}
											</a>
										</p>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="Product__more-products row">
					<div className="container">
						<div className="col">
							<div className="row">
								<h2>Våre produkter</h2>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row">
							<div className="col">
								{this.props.searchDone && (
									<p>
										Viser {this.props.list.length} av {this.props.resultcount} resultater.
									</p>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ProductList list={this.props.list} onInfinityScroll={this.infinityscrollLoad} />
								{this.renderCrawlerLink()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		searchedCompany: state.CompanyReducer.searchedCompany,
		companyinfo: state.CompanyReducer.companyInfo,
		company_isLoading: state.CompanyReducer.company_isLoading,
		notFound: state.CompanyReducer.company_notFound,
		list: state.CompanyReducer.companyProductList,
		resultcount: state.CompanyReducer.number,
		isLoading: state.CompanyReducer.companyproduct_isLoading,
		searchDone: state.CompanyReducer.companyproduct_searchDone,
		search: state.CompanyReducer.query
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getCompany: companyID => {
			dispatch(getCompanyInfo(companyID));
		},
		makeFacetSearch: (companyID, skip) => {
			dispatch(filterLeverandor(companyID, skip));
		},
		loadMoreResults: (search, skip) => {
			dispatch(companyInfinityScroll(search, skip));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyOverview);
