import React from 'react';
import ProductInfo from './ProductInfo';
import { connect } from 'react-redux';
import { fetchProduct, fetchAllergener } from '../../redux/product';
import { loadReciepes } from '../../redux/recipesearch';
import { getCompanyInfo } from '../../redux/company';
import { filterLeverandor } from '../../redux/searchfilter';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';

class ProductPage extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveArticles = this.retrieveArticles.bind(this);
        this.getCompany = this.getCompany.bind(this);
    }

    componentWillMount() {
        if (!this.props.notFound)
        {
            if (typeof(this.props.product) == 'undefined' || 
                this.props.product.produktId != this.props.match.params.productid) {
                this.props.getProduct(this.props.match.params.productid);
            }
            this.props.getAllergener();
            this.getCompany(this.props.product);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.productid != this.props.match.params.productid) {
            this.props.getProduct(nextProps.match.params.productid);
        }
        if (nextProps.product != this.props.product) {
            this.getCompany(nextProps.product);
        }
    }

    retrieveArticles() {
        if (this.props.articleproductid != this.props.product.produktId) {
            this.props.getArticles(this.props.product.fellesProduktnavn, this.props.product);
        }
    }

    getCompany(product) {
        if (product) {
            this.props.getCompanyInformation(product.pakninger[0].firmaID);
            this.props.makeFacetSearch(product.pakninger[0].firmaNavn, 0);
        }
    }

    render() {
        if (this.props.notFound) {
            return <h4>Fant ikke produkt med id {this.props.match.params.productid}.</h4>;
        }

        return (
            <div id="productpage">
                {(!this.props.product || this.props.isLoading) ? <PageLoadSpinner /> : <ProductInfo product={this.props.product} articles={this.props.articles} articlesearch={this.props.articlesearch} company={this.props.company} allergener={this.props.allergener} />}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        product: state.ProductReducer.product,
        isLoading: state.ProductReducer.isLoading,
        notFound: state.ProductReducer.notFound,
        allergener: state.ProductReducer.allergener,
        articles: state.RecipeSearchReducer.list,
        articlesearch: state.RecipeSearchReducer.searching,
        articleproductid: state.RecipeSearchReducer.productid,
        company : {
            companyinfo: state.CompanyReducer.companyInfo,
            company_isLoading: state.CompanyReducer.company_isLoading,
            list: state.CompanyReducer.companyProductList,
            list_isLoading: state.CompanyReducer.companyproduct_isLoading
        }
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        getProduct: (id) => { dispatch(fetchProduct(id)) },
        getAllergener: () => { dispatch(fetchAllergener()) },
        getArticles: (query, product) => { dispatch(loadReciepes(query, product)) },
        getCompanyInformation: (firmaID) => { dispatch(getCompanyInfo(firmaID)) },
        makeFacetSearch: (lev, skip) => { dispatch(filterLeverandor(lev, skip)) }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)