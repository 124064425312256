import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'
import { searchCompanies } from '../../redux/company';
import SearchBar from '../Utility/SearchBar/SearchBar';
import SEOLink from "../Utility/SEOLink/SEOLink";
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';
import { getConfig } from '../../config';

let timeout;
let InfinityScrollTimeout = false;
let page = 0;

const apiUrl = getConfig().API.WebApiBaseUrl;

class CompanyList extends React.Component {
    
	constructor(props) {
		super(props);

		this.search = this.search.bind(this);
		this.resetSearch = this.resetSearch.bind(this);
		this.keyUp = this.keyUp.bind(this);
        this.handleSearchUpdate = this.handleSearchUpdate.bind(this);
        this.debounce = this.debounce.bind(this);
        this.lazyLoad = this.lazyLoad.bind(this);
        this.hasReachedBottom = this.hasReachedBottom.bind(this);
    }

	componentWillMount() {
        if (this.props.companies.length === 0) {
            this.props.search();
		}
	}

    componentDidMount() {
        document.title = 'Leverandører - VetDuAt';
        this.lazyLoad();
        window.addEventListener('scroll', this.lazyLoad);
        const queries = queryString.parse(this.props.location.search);
        if(queries.q) {
            this.props.search(queries.q, 0);
            this.props.setSearchText(queries.q);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.lazyLoad);
    }

    lazyLoad() {
        if (this.hasReachedBottom() && !InfinityScrollTimeout && this.props.companies.length < this.props.totalCount) {
            InfinityScrollTimeout = true;
            this.nextPage();
            setTimeout(() => { InfinityScrollTimeout = false }, 1000);
        }
    }

    hasReachedBottom() {
        const companyList = document.getElementById("company-list");
        if (!companyList)
            return false;

        var list = companyList.getBoundingClientRect();
        return (list.bottom * 0.8 <= (window.innerHeight || document.documentElement));
    }

	handleSearchUpdate(val) {
		this.props.setSearchText(val.target.value);
	}

    search() {
        page = 0;
        const q = queryString.stringify({q: this.props.searchText});
        this.props.history.push({
            path: '/firma',
            search: q
        });
		this.props.search(this.props.searchText, page);
    }

    nextPage() {
        page++;
        this.props.search(this.props.searchText, page);
    }

    debounce(func, wait, immediate) {
        return () => {
            const context = this,
                args = arguments;
            const later = () => {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

	//Keyup
	keyUp(e) {
		if (e.key === 'Enter') {
			this.search();
            document.getElementById('search-term').blur();
		} else {
            if(this.props.searchText && this.props.query) {
                //this.debounceSuggest();
                this.debounce(() => {
                    this.search();
                }, 500)();
            }
		}
	}

    resetSearch(term) {
        this.props.setSearchText(term);
        this.props.search('');
    }

	render() {
		return (
			<div className="SearchContainer">
				<div className="row">
					<SearchBar
						backgroundColor="#e20076"
						animated={true}
						suggestions={[]}
						handleOnChange={this.handleSearchUpdate}
						text={this.props.searchText === '*' ? '' : this.props.searchText}
						handleKeyUp={this.keyUp}
						placeholder={'Søk etter leverandør'}
                        resetSearch={this.resetSearch}
					/>
				</div>
                <div className="row">
                    <div className="col">
                        <div className="Company container">
                            <div className="Company__result-heading col">
                                <div className="row">Våre leverandører</div>
                            </div>
                            {!this.props.loading && this.props.companies ? (
                                <div>
                                    <div className="Company__result-count col">
                                        <div className="row">
                                            {this.props.companies.length > 0 ? `Viser ${this.props.companies.length} av ${this.props.totalCount} leverandører` : 'Ingen treff'}
                                        </div>
                                    </div>
                                    <div className="CompanyList col" id="company-list">
                                        <div className="row">
                                            {this.props.companies.map((company, index) => {
                                                return (
                                                    <SEOLink base="/firma/" linkid={company.firmaID} firma={company.navn} key={'company-' + index} className="CompanyListItem">
                                                        {company.mainbildeUrl ? (
                                                            <img src={company.mainbildeUrl} alt={company.navn} className="CompanyListItem__image" />
                                                        ) : (
                                                            <img
                                                                src={apiUrl + '/api/Images/Medium/unknown'}
                                                                alt={company.navn}
                                                                className="CompanyListItem__image"
                                                            />
                                                        )}
                                                        <div className="CompanyListItem__title">{company.navn}</div>
                                                        <div className="CompanyListItem__subtitle">({company.count} produkter)</div>
                                                    </SEOLink>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            ) : <PageLoadSpinner /> }
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		companies: state.CompanyReducer.companyList,
		searchText: state.CompanyReducer.searchQuery || "",
		query: state.CompanyReducer.query,
        loading: state.CompanyReducer.companylist_isLoading,
        totalCount: state.CompanyReducer.totalCompanyCount
	};
}

function mapDispatchToProps(dispatch) {
	return {
		search: (term, page) => {
			dispatch(searchCompanies(term, page));
		},
        setSearchText: text => {
			dispatch({ type: 'SET_COMPANY_SEARCH_TEXT', searchQuery: text });
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
