import React from 'react';
import ProductListItem from './ProductListItem';
import { getProductImageUrl } from '../Product/ProductImageHelper';

import './ProductList.scss';

var lazy = [];
var InfinityScrollTimeout = false;

export default class ProductList extends React.Component {
    constructor(props) {
        super(props);

        this.setLazy = this.setLazy.bind(this);
        this.isInViewport = this.isInViewport.bind(this);
        this.hasReachedBottom = this.hasReachedBottom.bind(this);
        this.lazyLoad = this.lazyLoad.bind(this);
    }

    componentDidMount() {
        this.lazyLoad();
        window.addEventListener('scroll', this.lazyLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.lazyLoad);
    }

    componentDidUpdate() {
        this.setLazy();
        this.lazyLoad();
    }

    isInViewport(element) {
        var rect = element.getBoundingClientRect();


        return (
            rect.bottom >= 0 && rect.right >= 0 &&

            (rect.top * 0.8) <= (
                window.innerHeight ||
                document.documentElement.clientHeight
            )

            &&

            (rect.left * 0.8) <= (
                window.innerWidth ||
                document.documentElement.clientWidth
            )
        );
    }

    hasReachedBottom() {
        var list = document.getElementById("product-list").getBoundingClientRect();

        return (list.bottom * 0.8 <= (window.innerHeight || document.documentElement));
    }

    lazyLoad() {
        if (lazy.length == 0) this.setLazy();
        for (var i = 0; i < lazy.length; i++) {

            if (this.isInViewport(lazy[i])) {
                var source = lazy[i].getAttribute("data-source");
                var storhusholdning = false;
                if (lazy[i].getAttribute("data-storhusholdning") && lazy[i].getAttribute("data-storhusholdning") == 'true') {
                    storhusholdning = true;
                }
                if (source != null && source.length > 0)
                {
                    if (lazy[i].tagName == 'IMG') {
                        lazy[i].setAttribute("src", getProductImageUrl('medium', source, storhusholdning));
                    }
                    else if (lazy[i].tagName == 'SOURCE') {
                        lazy[i].setAttribute("srcset", getProductImageUrl('small', source, storhusholdning));
                    }
                    lazy[i].removeAttribute("data-source");
                }
            }
        }
        if (this.hasReachedBottom() && !InfinityScrollTimeout)
        {
            InfinityScrollTimeout = true;     
            if (this.props.onInfinityScroll) {
                this.props.onInfinityScroll();
        
            }
            setTimeout(() => { InfinityScrollTimeout = false }, 1000);
        }
    }

    setLazy() {
        lazy = [];
        lazy = document.getElementsByClassName("lazyload");
    }

    render() {
        return (
            <div className={"ProductList col " + this.props.className} id="product-list">
                <div className="row">
                    {this.props.list.map((entry, index) => {
                         return (<ProductListItem product={entry} key={index + "-" + entry.produktID} number={index} />);
                    })
                    }   
                </div>
        </div>
      );
    }
}