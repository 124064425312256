// import { fetch, addTask } from 'domain-task';

var initialState = {
    list: [],
    productid: -1
}

export function loadReciepes(query, product=null) {
    return (dispatch, getState) => {
        if (product == null) {
            dispatch(makeSearch({ search: "", categoryFilter: query, facets: false, productid: 0, excerpt: true, top: 20 }, "RECIPE_SEARCH_COMPLETED"));
        }
        else {
            dispatch(makeSearch({ search: query, productid: product.produktId, top: 5 }, "RECIPE_SEARCH_COMPLETED"));
        }
    }
}

function makeSearch(body, type, showLoader = true) {
    return (dispatch, getState) => {
        let task = fetch(global.backend_api + "/api/recipes/search",
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            })
            .then(data => data.json().then(data => dispatch({ type: type, data: data, search: body.search, productid: body.productid, query: body.search})))
            .catch(error => { console.log("Error: " + JSON.stringify(error)); dispatch({ type: "SEARCH_RECIPE_FAILED", query: body.search }); });
        //// addTask(task);
        dispatch({ type: "SEARCHING_RECIPE", query: body.search });
    }
}


export function getWordpressRecipe(id) {
    return (dispatch, getStore) => {
        var body = {
            "id": id
        };
        let task = fetch(global.backend_api + "/api/recipes/get",
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            })
            .then(data => data.json().then(data => dispatch(getWordpressPageAction(data))))
            .catch(error => dispatch({ type: "REQUEST_WORDPRESS_RECIPE_PAGE_FAILED" }));

        // addTask(task);
        dispatch({ type: "REQUESTING_WORDPRESS_RECIPE_PAGE" });
    }
}


export function getWordpressPageAction(page) {
    return {
        type: "GET_WORDPRESS_RECIPE_PAGE",
        page: page
    }
}

export const RecipeSearchReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SEARCHING_RECIPE":
            return Object.assign({}, state, {
                list: [],
                searching: true,
                productid: -1,
                query: action.query
            });
        case "RECIPE_SEARCH_COMPLETED":
            return Object.assign({},
                state,
                {
                    list: action.data.value,
                    searching: false,
                    productid: action.productid,
                    query: action.query
                });
        case "SEARCH_RECIPE_FAILED":
            return Object.assign({}, state, {
                list: [],
                searching: false,
                productid: -1,
                query: action.query
            });
        case "REQUESTING_WORDPRESS_RECIPE_PAGE":
            return Object.assign({}, state, {
                isLoading: true,
                notFound: false
            });
        case "GET_WORDPRESS_RECIPE_PAGE":
            return Object.assign({}, state, {
                page: action.page,
                isLoading: false,
                notFound: false
            });
        case "REQUEST_WORDPRESS_RECIPE_PAGE_FAILED":
            return Object.assign({}, state, {
                page: null,
                isLoading: false,
                notFound: true
            });
        default:
            return state;
    }
}