import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../src/Components/Utility/Header/Header';
import Footer from '../src/Components/Utility/Footer/Footer';

import Home from './Components/Home/HomeComponent';
import ProductSearch from './Components/ProductSearch/ProductSearch';
import FacetSearch from './Components/ProductSearch/FacetSearch';
import ProductPage from './Components/ProductPage/ProductPage';
import BlogRender from './Components/Wordpress/BlogRender';
import RecipeRender from './Components/Wordpress/RecipeRender';
import RecipeSearch from './Components/RecipeSearch/RecipeSearch';
import CompanyList from './Components/Company/CompanyList';
import CompanyOverview from './Components/Company/CompanyOverview';
import AboutComponent from "./Components/About/AboutComponent";
import ContactComponent from "./Components/Contact/ContactComponent";
// import Chat from './Components/Chat/Chat';



function App() {
  return (
    <div>
    <Header />
    <main role="main" className="container-fluid">
    <Switch>
      <Route exact path="/search/varemerke/:varemerke" component={FacetSearch} />
      <Route exact path="/search/:skip" component={ProductSearch} />
      <Route exact path="/produkt" component={ProductSearch} />
      <Route exact path="/produkt/:productid/:companyName?/:productName?" component={ProductPage} />
      <Route exact path="/blog/:pageid" component={BlogRender} />
      <Route exact path="/recipe/:pageid" component={RecipeRender} />
      <Route exact path="/recipesearch" component={RecipeSearch} />
      <Route exact path="/recipesearch/:query" component={RecipeSearch} />
      <Route exact path="/firma" component={CompanyList} />
      <Route exact path="/firma/:companyID/:name?" component={CompanyOverview} />
      <Route exact path="/om" component={AboutComponent} />
      <Route exact path="/kontakt" component={ContactComponent} />
      <Route exact path="/" component={Home} />
      </Switch>
    </main>
    {/* <Chat /> */}
    <Footer />
</div>
);
}

export default App;
