import { filterNewSearchRemove } from './searchfilter';
import { getConfig } from '../config';

const apiUrl = getConfig().API.WebApiBaseUrl;

var initialState = {
    list: [],
    query: "",
    searchQuery:"",
    count: 0,
    isLoading: false,
    searchDone: false,
    facets: {
        "Produksjonsland": { list: [], count: 10 },
        "AllergenerInneholder": { list: [], count: 10 },
        "AllergenerInneholderIkke": { list: [], count: 10 },
        "AllergenerKanInneholde": { list: [], count: 10 },
        "KategoriNavn": { list: [], count: 10 },
        "Varemerke": { list: [], count: 10 },
        "Varegruppenavn": { list: [], count: 10 },
        "FirmaNavn": { list: [], count: 10 },
        "MerkeOrdninger": { list: [], count: 10 },
        "ErStorhusholdningsprodukt": { list: [], count: 10 },
    },
    searchfilter: {
        "facets": [
            "Produksjonsland,count:10,sort:count",
            "AllergenerInneholder,count:10,sort:count",
            "AllergenerInneholderIkke,count:10,sort:count",
            "AllergenerKanInneholde,count:10,sort:count",
            "KategoriNavn,count:10,sort:count",
            "Varemerke,count:10,sort:count",
            "Varegruppenavn,count:10,sort:count",
            "FirmaNavn,count:10,sort:count",
            "MerkeOrdninger,count:10,sort:count",
            "ErStorhusholdningsprodukt,count:10,sort:count",
        ],
        top: 20,
        skip: 0,
        count: true,
        number: 0,
        search: ""
    }
}

export function filterSearch(filter) {
    return (dispatch, getState) => {
        var test = getState().SearchReducer.searchfilter;
        var body = Array.isArray(test) ? [...getState().SearchReducer.searchfilter] : {...getState().SearchReducer.searchfilter};
        body.search = getState().SearchReducer.query;
        body.skip = 0;

        if (filter) {
            body.filter = filter;
        }
        else {
            delete body["filter"];
        }
        console.log("1. Filtersearch fired..");
        dispatch(makeSearch(body, "SEARCH_COMPLETE"));
    }
}

export function facetSearch(filter, skip=0) {
    return (dispatch, getState) => {
        var body = {...initialState.searchfilter};
        body.search = "";
        body.skip = skip;

        if (filter) {
            body.filter = filter;
        }
        else {
            delete body["filter"];
        }

        dispatch(makeSearch(body, "SEARCH_COMPLETE"));
    }
}

export function infinityScroll(search, skip) {
    return (dispatch, getState) => {
        var body = {...getState().SearchReducer.searchfilter};
        body.skip = skip;
        body.search = search;
        body.filter = getState().SearchReducer.filter;

        console.log("Infinite search fired");

        dispatch(makeSearch(body, "INFINITY_SCROLL", false));
    }
}


export function searchAction(search, skip=0) {
    return (dispatch, getState) => {
        var body = {...initialState.searchfilter};
        body.search = search;
        body.skip = skip;

        delete body["filter"];

        console.log("SearchAction fired...");

        dispatch(filterNewSearchRemove());
        dispatch(makeSearch(body, "SEARCH_COMPLETE"));
    }
}

export function expandFacetCount(facet) {
    return (dispatch, getState) => {

        var body = getState().SearchReducer;

        if (body.facets[facet]) {
            body.facets[facet].count += 10;
        }

        var facets = [];
        for (var key in body.facets) {
            facets.push(key + ",count:" + body.facets[key].count + ",sort:count");
        }

        body = body.searchfilter;
        body.facets = facets;

        body.search = getState().SearchReducer.query;

        console.log("Infinite search fired");

        dispatch(makeSearch(body, "EXPAND_FACETS"));
    }
}

export function getProductCount() {
    return (dispatch, getState) => {
        let task = fetch(apiUrl + "api/products/count")
            .then(data => data.text().then(data => dispatch({ type: "HOMEPAGE_TOTAL_COUNT", count: data }))
            )
            .catch(error => dispatch({ type: "HOMEPAGE_TOTAL_COUNT_FAILED" }))

        // addTask(task);
        console.log("CONFIG ER : ", getConfig().API.WebApiBaseUrl);
    }
}


function makeSearch(body, type, showLoader = true) {
    console.log("MakeSearch fired....");
    return (dispatch, getState) => {
        fetch(apiUrl + "api/products/search",
            {
                headers: {'Content-Type': 'application/json'},
                method: "POST",
                body: JSON.stringify(body)
            })
            .then(data => data.json().then(function(data) {
               dispatch({ type: type, data: data, search: body.search, filter: body.filter })
            } 
            ))
            .catch(error => dispatch({type: "SEARCH_FAILED", search: body.search }))
            // .finally(res => dispatch({ type: "CLEAR_SUGGESTIONS" }));
        
        if (showLoader) {
            dispatch({ type: "SEARCHING" });
        }
    }
}


function sortFacets(facets) {
    var facetlist = {};
    for (var obj in facets) {
        facetlist[obj] = { list: facets[obj].facets, count: facets[obj].facets.length, hasMore: facets[obj].hasMoreFacets };
    }
    return facetlist;
}

export const SearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_SEARCH_FILTER":
            return Object.assign({}, state, {
                searchfilter: {
                    facets: state.searchfilter.facets,
                    top: 20,
                    skip: 0,
                    count: true,
                    filter: action.filter
                }
            });
        case "REMOVE_SEARCH_FILTER":
            return Object.assign({}, state, {
                searchfilter: {
                    "facets": state.searchfilter.facets,
                    top: 20,
                    skip: 0,
                    count: true
                }
            });
        case "SEARCHING":
            return Object.assign({}, state, { isLoading: true, searchDone: false});
        case "SET_SEARCH_TEXT":
            var obj = {
                searchQuery: action.searchQuery,

            };
            return Object.assign({}, state, obj);
        case "SEARCH_COMPLETE":
            var obj = {
                list: action.data.products,
                facets: sortFacets(action.data.facets),
                query: action.search,
                searchfilter: state.searchfilter,
                number: action.data.searchMetadata.count,
                searchId: action.data.searchId,
                isLoading: false,
                searchDone: true,
                filter: action.filter
            };
            return Object.assign({}, state, obj);
        case "EXPAND_FACETS":
            var obj = {
                facets: sortFacets(action.data.facets),
                query: action.search,
                searchfilter: state.searchfilter,
                number: action.data.searchMetadata.count,
                searchId: action.data.searchId,
                isLoading: false,
                searchDone: true,
                filter: action.filter
            };
            return Object.assign({}, state, obj);
        case "SEARCH_FAILED":
            return Object.assign({}, state, {
                isLoading: false,
                searchDone: true,
                number: 0,
                query: action.search,
                list: []
            });
        case "INFINITY_SCROLL":
            return Object.assign({}, state, { list: state.list.concat(action.data.products)});
        case "CLEAR_SEARCH":
            return state; //Object.assign({}, initialState);
        case "HOMEPAGE_TOTAL_COUNT":
            return Object.assign({}, state, { homepageCount: action.count });
        case "HOMEPAGE_TOTAL_COUNT_FAILED":
            return Object.assign({}, state, { homepageCount: "?"});
        default:
            return state;
    }
}