import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Utility/Button/Button';
import ProductList from '../ProductSearch/ProductList';
import SEOLink from "../Utility/SEOLink/SEOLink";
import { merkeOrdningImage } from "../Product/MerkeOrdningImage";
import renderHTML from 'html-react-parser';
import { AllergenTable } from './AllergenTable';
import { getProductImageUrl } from '../Product/ProductImageHelper';

export default class ProductInfo extends React.Component {
	constructor(props) {
		super(props);

        this.state = { expandIngredienser: 0, expandProdukt: 0, expandNaring: 0, expandMerke: 0, expandAllergener: 0, hideAllergener: false, showAllergenTable: false };

		this.renderWordpressPosts = this.renderWordpressPosts.bind(this);
	}

	componentWillMount() {
    }

    shouldWeHideAllergener(kategori) {
        const includedCategories = ["Næringsmidler", "Vin, brennevin og sterrkøl", "Øl og alkoholholdig drikke"];
        if (includedCategories.indexOf(kategori) === -1) {
            return true;
        }
        return false;
    }

	componentDidMount() {
		document.title = `${this.props.product.fellesProduktnavn ? this.props.product.fellesProduktnavn + ' - ' : ''}${
			this.props.product.pakninger[0].firmaNavn
            } - VetDuAt`;
		
			window.scrollTo(0,0);
	}

	expandToggle(type) {
		var obj = {};
		obj[type] = !this.state[type];

		this.setState(obj);
    }

    renderProductBilde(product) {
        if (product.pakninger[0].erTobakk) {
            return <img
                src="/images/tobakk.jpg"
                alt={product.fellesProduktnavn}
            />;
        }
        else if (product.pakninger[0].erSnus) {
            return <img
                src="/images/snus.jpg"
                alt={product.fellesProduktnavn}
            />;
        }
        else if (product.pakninger[0].harBilde) {
            return <img
                src={getProductImageUrl('medium', product.pakninger[0].gtin, product.pakninger[0].erStorhusholdningsprodukt)}
                alt={product.fellesProduktnavn}
            />;
        }
            
        return <img
            src={global.backend_api + '/api/Images/Medium/unknown'}
            alt={product.fellesProduktnavn}
        />;
    }

	renderWordpressPosts() {
		return (
			<div className="wordpress-posts">
				{this.props.articles.map((recipe, index) => {
					return (
						<div className="recipe" key={'recipe_' + index}>
							<Link className="wordpress-link" to={'/recipe/' + recipe.id}>
								<img src={recipe.featuredMedia} />
								<strong>{recipe.title}</strong>
							</Link>
						</div>
					);
				})}
			</div>
		);
    }

	render() {
		return (
			<div className="ProductPage row">
				<div className="col">
					<div className="Product container-fluid">
						<div className="row">
							<div className="Product__main container">
								<div className="row">
									<div className="col">
										<h2 className="Product__title">{this.props.product.fellesProduktnavn}</h2>
										<div className="Product__info">
                                            {this.props.product.pakninger[0].mengde} {this.props.product.pakninger[0].mengdetypeenhet}<br />
                                        </div>
                                        <div className="Product__extrainfo">
                                            EPD nr: {this.props.product.epDnr}<br />
                                            GTIN: {this.props.product.pakninger[0].gtin}
                                        </div>
										<SEOLink base="/firma/" linkid={this.props.product.pakninger[0].firmaID} firma={this.props.product.pakninger[0].firmaNavn} className="Product__supplier-link">
											{this.props.product.pakninger[0].firmaNavn}
										</SEOLink>
										{this.props.product.pakninger[0].merkeOrdninger.length > 0 ? (
											<ul className="Product__labels">
                                                {this.props.product.pakninger[0].merkeordningBildeUrlList.map((moUrl, idx) => {
													return (
														<li className="Product__label" key={'ProductLabel_' + idx}>
                                                            {merkeOrdningImage(moUrl, "Product__label-image")}
														</li>
													);
												})}
											</ul>
										) : (
											''
										)}
									</div>
                                    <div className="Product__image col">
                                        {this.renderProductBilde(this.props.product)}
									</div>
								</div>
							</div>
						</div>
						<div className="Product__about row">
							<div className="container">
								<div className="row">
									<div className="col">
										{this.props.product.pakninger[0].informasjonstekst ? (
											<div>
												<h3>Om produktet</h3>
												<p>{this.props.product.pakninger[0].informasjonstekst}</p>
											</div>
										) : (
											''
										)}
                                        <div className="Product__about-details">
											{this.props.product.pakninger[0].ingredienser ? (
												this.state.expandIngredienser ? (
													<div className="Accordion Accordion--is-open">
														<header
															className="Accordion__header"
															onClick={this.expandToggle.bind(this, 'expandIngredienser')}
														>
															Ingredienser
														</header>
                                                        <div className="Accordion__content">
                                                            <p>{renderHTML(this.props.product.pakninger[0].parsedIngredients)}</p>
														</div>
													</div>
												) : (
													<div className="Accordion">
														<header
															className="Accordion__header"
															onClick={this.expandToggle.bind(this, 'expandIngredienser')}
														>
															{' '}
															Ingredienser
														</header>
													</div>
												)
											) : (
												''
                                                )}
                                            {!this.shouldWeHideAllergener(this.props.product.pakninger[0].kategoriNavn) ? (
                                                this.state.expandAllergener ? (
                                                    <div className="Accordion Accordion--is-open">
                                                        <header
                                                            className="Accordion__header"
                                                            onClick={this.expandToggle.bind(this, 'expandAllergener')}
                                                        >
                                                            Allergener
														    </header>
                                                        <div className="Accordion__content">
                                                            <AllergenTable allergener={this.props.allergener} inneholder={this.props.product.pakninger[0].allergenerInneholder} inneholderIkke={this.props.product.pakninger[0].allergenerInneholderIkke} kanInneholde={this.props.product.pakninger[0].allergenerKanInneholde} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                        <div className="Accordion">
                                                            <header
                                                                className="Accordion__header"
                                                                onClick={this.expandToggle.bind(this, 'expandAllergener')}
                                                            >
                                                                {' '}
                                                                Allergener
													    </header>
                                                        </div>
                                                    )) : (
                                                    ''
                                                )
                                            }
											{this.props.product.pakninger[0].produksjonsland ||
											this.props.product.pakninger[0].firmaNavn ||
											this.props.product.pakninger[0].minimumstemperaturCelsius ||
											this.props.product.pakninger[0].maksimumstemperaturCelcius ? (
												this.state.expandProdukt ? (
													<div className="Accordion Accordion--is-open">
														<header
															className="Accordion__header"
															onClick={this.expandToggle.bind(this, 'expandProdukt')}
														>
															Produktinfo
														</header>
														<div className="Accordion__content">
															<table className="Product__table">
																<tbody>
																	{this.props.product.pakninger[0].produksjonsland ? (
																		<tr>
																			<td>Produksjonsland</td>
																			<td>{this.props.product.pakninger[0].produksjonsland}</td>
																		</tr>
																	) : (
																		''
																	)}
																	{this.props.product.pakninger[0].firmaNavn ? (
																		<tr>
																			<td>Leverandør</td>
																			<td>{this.props.product.pakninger[0].firmaNavn}</td>
																		</tr>
																	) : (
																		''
																	)}
																	{this.props.product.pakninger[0].minimumstemperaturCelsius ||
																	this.props.product.pakninger[0].maksimumstemperaturCelcius ? (
																		<tr>
																			<td>Oppbevaring</td>
																			<td>
																				{this.props.product.pakninger[0].minimumstemperaturCelsius}°c til{' '}
																				{this.props.product.pakninger[0].maksimumstemperaturCelcius}°c
																			</td>
																		</tr>
																	) : (
																		''
																	)}
																</tbody>
															</table>
														</div>
													</div>
												) : (
													<div className="Accordion">
														<header
															className="Accordion__header"
															onClick={this.expandToggle.bind(this, 'expandProdukt')}
														>
															Produktinfo
														</header>
													</div>
												)
											) : (
												''
											)}
											{this.props.product.pakninger[0].deklarasjonListe.length > 0 ? (
												this.state.expandNaring ? (
													<div className="Accordion Accordion--is-open">
														<header
															className="Accordion__header"
															onClick={this.expandToggle.bind(this, 'expandNaring')}
														>
															Næringsinnhold
														</header>
														<div className="Accordion__content">
															<p>Næringsinnhold pr. 100g/ml</p>
															<table className="Product__table">
																<tbody>
																	{this.props.product.pakninger[0].deklarasjonListe.map((entry, index) => {
																		var res = entry.split('=');
																		return (
																			<tr key={'Dec-' + index}>
																				<td>{res[0]}</td>
																				<td>{parseFloat(res[1])}</td>
																			</tr>
																		);
																	})}
																</tbody>
															</table>
														</div>
													</div>
												) : (
													<div className="Accordion">
														<header
															className="Accordion__header"
															onClick={this.expandToggle.bind(this, 'expandNaring')}
														>
															Næringsinnhold
														</header>
													</div>
												)
											) : (
												''
											)}
											{/*this.props.product.pakninger[0].merkeOrdninger.includes('nyt_norge') ||
											this.props.product.pakninger[0].merkeOrdninger.includes('nokkelhullet') ? (
												this.state.expandMerke ? (
													<div className="Accordion Accordion--is-open">
														<header className="Accordion__header" onClick={this.expandToggle.bind(this, 'expandMerke')}>
															Merkeordninger
														</header>
														<div className="Accordion__content">
															<div className="container-fluid">
																{this.props.product.pakninger[0].merkeOrdninger.includes('nyt_norge') && (
																	<div className="row Accordion__info-block">
																		<div className="col-3">
																			<img src="/images/merkeordninger/NytNorge.png" alt="" />
																		</div>
																		<div className="col-9">
																			<p>
																				<b>Nyt Norge</b> skal gjøre norske produkter mer synlige i butikkene og dermed
																				enkere å finne for alle som vil ha matvarer basert på norske råvarer.
																			</p>
																		</div>
																	</div>
																)}
																{this.props.product.pakninger[0].merkeOrdninger.includes('nokkelhullet') && (
																	<div className="row Accordion__info-block">
																		<div className="col-3">
																			<img src="/images/merkeordninger/Nokkelhullet.png" alt="" />
																		</div>
																		<div className="col-9">
																			<p>
																				<b>Nøkkelhullet</b> er en såkalt positivmerking som skal gjøre det lettere for
																				forbrukere å gjøre sunnere valg når de handler mat, uavhengig av lese- og
																				språkferdigheter. Mattilsynet og Helsedirektoratet har ansvar for merkeordningen
																				i Norge.
																			</p>
																		</div>
																	</div>
																)}
															</div>
														</div>
													</div>
												) : (
													<div className="Accordion">
														<header className="Accordion__header" onClick={this.expandToggle.bind(this, 'expandMerke')}>
															Merkeordninger
														</header>
													</div>
												)
											) : (
												''
											)*/}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="Product__disclaimer row">
							<div className="container">
								<div className="row">
									<div className="col">
										<p>
											Informasjon om produktene kan inneholde feil eller mangler, og må brukes med varsomhet! Ved
											tilberedelse av mat, anbefales alltid å lese innholdsdeklarasjonen på vareforpakningen.
										</p>
										<p>
											Tradesolution er ikke ansvarlig for eventuelle feil eller mangler i produktinformasjon som gjengis
											på VetDuAt.no
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="Product__producer Product__producer--is-condensed row">
							<div className="container">
								<div className="row">
									<div className="col">
										<div className="Product__producer-contact">
											<div className="row">
												{this.props.company.companyinfo.logoUrl ? (
													<div className="Product__producer-contact-logo col">
														<img
															src={this.props.company.companyinfo.logoUrl}
															alt={`${this.props.company.companyinfo.navn}'s logo`}
														/>
													</div>
												) : (
													''
												)}
												<div className="col">
													<h2 className="Product__producer-title">{this.props.product.pakninger[0].firmaNavn}</h2>
													{this.props.company.companyinfo.tlf ? (
														<p className="Product__producer-phone">{this.props.company.companyinfo.tlf}</p>
													) : (
														''
													)}
													{this.props.company.companyinfo.ePost ? (
														<a
															className="Product__producer-mail"
															href={`mailto: ${this.props.company.companyinfo.ePost}`}
														>
															{this.props.company.companyinfo.ePost}
														</a>
													) : (
														''
													)}
												</div>
											</div>
										</div>
										{this.props.company.companyinfo.beskrivelse ? (
											<p className="Product__producer-description">{this.props.company.companyinfo.beskrivelse}</p>
										) : (
											''
										)}
                                        <SEOLink base="/firma/" linkid={this.props.product.pakninger[0].firmaID} firma={this.props.product.pakninger[0].firmaNavn} className="Product__producer-read-more">
                                            <Button text="Mer om leverandøren" rounded={true} caps={true} />
                                        </SEOLink>
									</div>
									<div className="col Product__producer-image">
										{this.props.company.companyinfo.miljobildeUrl ? (
											<img
												src={this.props.company.companyinfo.miljobildeUrl}
												alt={`${this.props.company.companyinfo.navn}'s miljøbilde`}
											/>
										) : (
											''
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="Product__more-products row">
							<div className="container">
								<div className="col">
									<div className="row">
                                        <h2>Flere produkter fra {this.props.product.pakninger[0].firmaNavn}</h2>
									</div>
								</div>
							</div>
							<div className="container">
								<div className="col">
									<div className="row">
										<ProductList list={this.props.company.list} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
