// import { fetch, addTask } from 'domain-task';
import {filterLeverandor} from "./searchfilter";
import { getConfig } from '../config';

const apiUrl = getConfig().API.WebApiBaseUrl;

var initialState = {
    companylist_isLoading: true,
	companyList: [],
	companyInfo: {
		beskrivelse: null,
		ePost: null,
		hjemmesideUrl: null,
		logoUrl: null,
		miljobildeUrl: null,
		navn: null,
		tlf: null
	},
	company_isLoading: true,
	searchedCompany: null,
	company_notFound: false,
	companyProductList: [],
	companyproduct_isLoading: true,
	companyproduct_searchDone: false,
	query: '',
	searchQuery: '',
    number: 0,
    totalCompanyCount: 0,
	searchFilter: {
		top: 20,
		skip: 0,
		count: true,
		number: 0,
		search: ''
	}
};

// export function getCompanyList(top) {
// 	return (dispatch, getState) => {
// 		let task = fetch(apiUrl + 'api/Company/PagedList?top=' + top)
// 			.then(data => data.json().then(data => dispatch(gotCompanyList(data))))
// 			.catch(err => {
// 				console.log(err);
// 				dispatch({ type: 'GOT_COMPANY_LIST_FAILED' });
// 			});

// 		// addTask(task);
// 		dispatch({ type: 'SEARCHING_COMPANY_LIST' });
// 	};
// }

function gotCompanyList(data) {
	return {
		type: 'GOT_COMPANY_LIST',
        list: data.items,
        totalCount: data.totalCount
	};
}

function concatCompanyList(data) {
    return {
        type: 'CONCAT_COMPANY_LIST',
        list: data.items
    };
}

export function getCompanyInfo(companyID) {
	return (dispatch, getState) => {
		let task = fetch(apiUrl + 'api/Company/' + companyID + '/Information')
			.then(data => data.text().then(data => {
				dispatch(gotCompanyInfo(JSON.parse(data)));
                dispatch(filterLeverandor(JSON.parse(data).navn));
            }))
			.catch(err => {
				console.log(err);
				dispatch({ type: 'GOT_COMPANY_INFO_FAILED' });
			});

		// addTask(task);
		dispatch({ type: 'SEARCHING_COMPANY_INFO' });
	};
}

export function searchCompanies(search, page) {
    const pageIndex = page || 0;
    const top = 50;
    const skip = (pageIndex * top);
	return (dispatch, getState) => {
        let task = fetch(`${apiUrl}api/Company/searchpaged/?q=${search || ""}&top=${top}&skip=${skip}`)
            .then(data => data.json().then(data => {
                if (page > 0) {
                    return dispatch(concatCompanyList(data));
                } else {
                    return dispatch(gotCompanyList(data));
                }
            }))
			.catch(err => {
				console.log(err);
				dispatch({ type: 'GOT_COMPANY_LIST_FAILED' });
			});

        // addTask(task);
        dispatch({ type: 'SEARCHING_COMPANY_LIST', query: search });
	};
}

function gotCompanyInfo(data) {
	return {
		type: 'GOT_COMPANY_INFO',
		info: data
	};
}

export function companyFacetSearch(filter, company, skip = 0) {
	return (dispatch, getState) => {
		var body = {...initialState.searchFilter};
		body.skip = skip;

		if (filter) {
			body.filter = filter;
		} else {
			delete body['filter'];
		}

		dispatch(makeSearch(body, 'COMPANYPRODUCT_SEARCH_COMPLETE', true, company));
	};
}

export function companyInfinityScroll(search, skip) {
	return (dispatch, getState) => {
		var test = getState().CompanyReducer.searchFilter;
		var body = Array.isArray(test) ? [...getState().CompanyReducer.searchFilter] : {...getState().CompanyReducer.searchFilter};
		body.skip = skip;
		body.search = search;
		body.filter = getState().CompanyReducer.filter;

		dispatch(makeSearch(body, 'COMPANYPRODUCT_INFINITY_SCROLL', false));
	};
}

function makeSearch(body, type, showLoader = true, company = null) {
	return (dispatch, getState) => {
		let task = fetch(apiUrl + 'api/products/search', {
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify(body)
		})
			.then(data =>
				data
					.json()
					.then(data =>
						dispatch({ type: type, data: data, search: body.search, filter: body.filter, company: company })
					)
			)
			.catch(error => dispatch({ type: 'COMPANYPRODUCT_SEARCH_FAILED', search: body.search }));

		// addTask(task);

		if (showLoader) {
			dispatch({ type: 'COMPANYPRODUCT_SEARCHING' });
		}
	};
}

export const CompanyReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SEARCHING_COMPANY_LIST':
			return Object.assign({}, state, {
				query: action.query
			});
		case 'SET_COMPANY_SEARCH_TEXT':
			return Object.assign({}, state, {
				searchQuery: action.searchQuery
			});
		case 'GOT_COMPANY_LIST_FAILED':
			return Object.assign({}, state, {
				companylist_isLoading: false,
				companyList: []
			});
		case 'GOT_COMPANY_LIST':
			return Object.assign({}, state, {
				companylist_isLoading: false,
                companyList: action.list,
                totalCompanyCount: action.totalCount
            });
        case 'CONCAT_COMPANY_LIST':
            return Object.assign({}, state, {
                companylist_isLoading: false,
                companyList: state.companyList.concat(action.list),
            });
		case 'SEARCHING_COMPANY_INFO':
			return Object.assign({}, state, {
				company_isLoading: true,
				query: action.query,
				companyInfo: {}
			});
		case 'GOT_COMPANY_INFO_FAILED':
			return Object.assign({}, state, {
				company_isLoading: false,
				companyInfo: {}
			});
		case 'GOT_COMPANY_INFO':
			return Object.assign({}, state, {
				company_isLoading: false,
				companyInfo: action.info
			});

		case 'COMPANYPRODUCT_INFINITY_SCROLL':
			return Object.assign({}, state, { companyProductList: state.companyProductList.concat(action.data.products) });
		case 'COMPANYPRODUCT_SEARCHING':
			return Object.assign({}, state, {
				companyproduct_isLoading: true,
				companyproduct_searchDone: false,
				companyProductList: []
			});
		case 'COMPANYPRODUCT_SEARCH_COMPLETE':
			var obj = {
				companyProductList: action.data.products,
				query: action.search,
				searchfilter: state.searchfilter,
				number: action.data.searchMetadata.count,
				companyproduct_isLoading: false,
				companyproduct_searchDone: true,
				filter: action.filter,
				searchedCompany: action.company
			};
			return Object.assign({}, state, obj);
		case 'COMPANYPRODUCT_SEARCH_FAILED':
			return Object.assign({}, state, {
				companyproduct_isLoading: false,
				companyproduct_searchDone: true,
				number: 0,
				query: action.search,
				companyProductList: []
			});
		default:
			return state;
	}
};
